.player-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  }
  
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

  .quiz-wrapper {
    /* position: relative; */
    /* padding-top: 56.25%; Player ratio: 100 / (1280 / 720) */
    /* width: 200px; */
    /* align-self: start; */
    /* align-self: center; */
    background-color: rgb(156, 140, 205);
    border-radius: 10px;
    margin: 10px;
    padding-bottom: 20px;
  }